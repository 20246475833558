/* Main container - covers the entire viewport and stays in the background */
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

/* Style for single image (non-slideshow mode) */
.single-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Swiper-specific styles */
.background-swiper {
  width: 100%;
  height: 100%;
}

.background-slide {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Override swiper fade transition to ensure proper crossfade */
.swiper-fade.swiper-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}

.swiper-fade .swiper-slide-active {
  pointer-events: auto;
}

.swiper-fade .background-image {
  transition: opacity;
}
